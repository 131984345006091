<template>
    <div class="footer">
        <div class="uk-grid-collapse uk-grid" uk-grid="">
            <div class="uk-width-expand@s uk-first-column">
                <p v-html="getCopyright[0].message"></p>
            </div>
            <div class="uk-width-auto@s">
                <nav class="footer-nav-icon">
                    <Social/>
                </nav>
            </div> 
        </div>
    </div>
</template>

<script>
import Social from '../Social'
export default {
    components: {
        Social
    },
    data(){
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',  
            copyright: [
                {lang: 'en', message: '&copy; Copyrights '+ new Date().getFullYear() +' How Of What. All rights reserved.'},
                {lang: 'ar', message: '&copy; حقوق النشر 2021 كيف ماذا. كل الحقوق محفوظة.'},
                {lang: 'fr', message: '&copy; Copyrights '+ new Date().getFullYear() +' Comment de quoi. Tous les droits sont réservés.'},
            ]
        }
    },
    computed: {
        getCopyright() {
            return this.copyright.filter(res => {
                return (res.lang == this.defaultLang) 
            })
        }
    }
}
</script>