<template>    
    <div id="wrapper">
        <Header/>
        <Nav/>
        <router-view></router-view>
        <Footer/>
    </div>    
</template>

<script>
import Header from "../components/Students/Header"
import Nav from "../components/Students/Nav"
import Content from "../components/Students/Content"
import Footer from "../components/Students/Footer"

export default {
    components: {
        Header, Nav, Content, Footer
    },
    data() {
        return {
           //specialAccount: this.$store.state.auth.isSpecial || 0 
        }
    },
    methods: {
        getUserData() {
            this.$store.dispatch('validateUser')
        },
        // setStyle() {
        //     if(this.specialAccount === 1) {
        //         document.querySelector('html').classList.add('special-user')
        //     }
        // }
    },
    created: function() {
        this.getUserData();
        //this.setStyle()
    },
    watch: {
        // "$store.state.auth.isSpecial": function() {
        //     this.specialAccount = this.$store.state.auth.isSpecial
        //     this.setStyle()
        // }
    }
}
</script>

<style>
  @import '../../public/css/framework.css';
  @import '../../public/css/course-style.css';
</style>