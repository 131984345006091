<template>
    <ul :class="className" v-if="socialMedias.length > 0">
        <li v-for="(data, index) of socialMedias" :key="index">
            <a :href="data.media_url" target="_blank" rel="nofollow" v-html="data.media_icon"></a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        htmlClass: String
    },
    data(){
        return {
            socialMedias: [],
            className: ''
        }
    },
    computed: {
        getSocialLinks(){
            this.socialMedias = this.$store.state.social.socialData
        }
    },
    created: function(){
        this.getSocialLinks
        this.className = this.$props.htmlClass
    },
    watch: {
        "$props.htmlClass": function() {
            this.className = this.$props.htmlClass
        },
        "$store.state.social.socialData": function() {
            this.getSocialLinks
        }
    }

}
</script>